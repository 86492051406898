import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CoherencePanel } from '@coherence-design-system/controls';
import { Stack } from '@fluentui/react';
import ActionButtons from '../../../common/buttons/ActionButtons';
import { CoherencePanelStyles } from '../../../common/styles/CommonStyleObjects';
import { IProjectNileWorkflow } from '../../../utils/types/IProjectNileWorkflow';
import { IGroupedChecklistItem } from '../../../utils/types/IGroupedChecklistItem';
import NileWorkflowSearchSelector from '../../../common/formFields/NileWorkflowSearchSelector';
import { mapWorkflowsToTags } from '../../../common/formFields/NileWorkflowTagPicker';

interface INileWorkflowsPanelProps {
  selectedWorkflows: IProjectNileWorkflow[];
  onSave: (selectedWorkflows: IProjectNileWorkflow[]) => void;
  closePanel: () => void;
}

const NileWorkflowsPanel = (props: INileWorkflowsPanelProps): JSX.Element => {
  const { closePanel, onSave, selectedWorkflows } = props;
  const [currentNileWorkflows, setCurrentNileWorkflows] = useState<IGroupedChecklistItem[]>(
    mapWorkflowsToTags(selectedWorkflows),
  );

  const form = useForm();
  const { handleSubmit } = form;

  const handleOnSave = () => {
    onSave(currentNileWorkflows);
    closePanel();
  };

  const onCheckChanged = (items: IGroupedChecklistItem[]) => {
    setCurrentNileWorkflows(items);
  };

  return (
    <CoherencePanel
      panelSize="500px"
      titleText="Select a Workflow"
      isOpen
      onDismiss={closePanel}
      hasCloseButton
      styles={CoherencePanelStyles}
      closeButtonAriaLabel="Close Select Workflow"
      onRenderFooter={(): JSX.Element => (
        <Stack>
          <ActionButtons
            mutationLoading={false}
            closePanel={closePanel}
            handleSubmit={handleSubmit(handleOnSave)}
            saveLabel="Save"
            saveTitle="Save"
            cancelLabel="Cancel"
            cancelTitle="Cancel"
          />
        </Stack>
      )}
    >
      <NileWorkflowSearchSelector
        selections={currentNileWorkflows}
        onCheckChanged={onCheckChanged}
      />
    </CoherencePanel>
  );
};
export default NileWorkflowsPanel;
