import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Checkbox,
  mergeStyleSets,
  NeutralColors,
  normalize,
  SearchBox,
  Stack,
  TooltipHost,
} from '@fluentui/react';
import { IProjectNileWorkflow } from '../../utils/types/IProjectNileWorkflow';
import GetProjectNileWorkflows from '../../utils/api/ProjectNileWorkflowsApi';
import { IGroupedChecklistItem } from '../../utils/types/IGroupedChecklistItem';
import useDebounce from '../../utils/hooks/useDebounce';
import { THROTTLE_SEARCH_TIMEOUT } from '../constants/SiteConstants';
import LoadingErrorMessage from '../errorContent/LoadingErrorMessage';

const styles = mergeStyleSets({
  searchContainer: {
    marginBottom: 20,
  },
  container: {
    overflow: 'auto',
  },
  itemContent: [
    normalize,
    {
      padding: '10px 5px 0 5px',
      height: 40,
      borderBottom: `1px solid ${NeutralColors.gray30}`,
      overflow: 'hidden',
    },
  ],
  checkLabel: {
    label: {
      whiteSpace: 'nowrap',
    },
  },
});

export interface INileWorkflowSearchSelectorProps {
  selections: IGroupedChecklistItem[];
  onCheckChanged: (items: IGroupedChecklistItem[]) => void;
}

const NileWorkflowSearchSelector = (props: INileWorkflowSearchSelectorProps): JSX.Element => {
  const { onCheckChanged, selections } = props;
  const [projectNileWorkflows, setProjectNileWorkflows] = useState<IProjectNileWorkflow[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, THROTTLE_SEARCH_TIMEOUT);
  const { data, loading, error } = useQuery(GetProjectNileWorkflows, {
    fetchPolicy: 'network-only',
    variables: { keyword: debouncedSearchTerm },
  });

  useEffect(() => {
    if (data?.projectNileWorkflows && !error && !loading) {
      const projectNileWorkflowsData: IProjectNileWorkflow[] = data?.projectNileWorkflows?.map(
        (workflow: IProjectNileWorkflow) => ({
          ...workflow,
          key: workflow.id,
          selected: selections?.some((selection) => selection.key === workflow.id) ?? false,
        }),
      );
      setProjectNileWorkflows(projectNileWorkflowsData);
    }
  }, [data, selections]);

  const onSearchChange = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string): void => {
    setSearchTerm(newValue);
  };

  // Convert the selected IGroupedChecklistItems to a list of IProjectNileWorkflow objects
  // and call check changed with new list of workflow objects
  const handleCheckChanged = (currentSelections: IGroupedChecklistItem[]): void => {
    const items: IProjectNileWorkflow[] = currentSelections?.map((selection) => {
      // Find the associated Workflow for the IGroupedChecklistItem
      const associatedWorkflow: IProjectNileWorkflow = data?.projectNileWorkflows?.find(
        (workflow: IProjectNileWorkflow) => workflow?.id === selection?.key,
      );
      if (associatedWorkflow) {
        return {
          key: associatedWorkflow.id,
          name: associatedWorkflow.name,
          id: associatedWorkflow.id, // Identifier required
          selected: selection.selected,
        };
      }
      return selection;
    });
    onCheckChanged(items);
  };

  const onRenderRow = (workflow: IProjectNileWorkflow, index: number) => {
    return (
      <div data-is-focusable className={styles.itemContent}>
        <TooltipHost content={workflow?.name} id={`workflow-checklist-item-${index}`}>
          <Checkbox
            label={workflow.name}
            checked={workflow.selected}
            className={styles.checkLabel}
            onChange={(ev: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
              let selectedWorkflows: IGroupedChecklistItem[] = [];
              if (checked) {
                selectedWorkflows = [workflow];
              } else {
                selectedWorkflows = [];
              }
              handleCheckChanged(selectedWorkflows);
            }}
          />
        </TooltipHost>
      </div>
    );
  };

  return (
    <Stack verticalAlign="start" verticalFill>
      <SearchBox
        value={searchTerm}
        onChange={onSearchChange}
        placeholder="Search Workflows"
        ariaLabel="Search Workflows"
      />
      <LoadingErrorMessage loading={loading} error={error} />
      <div className={styles.container} data-is-scrollable>
        {projectNileWorkflows?.map((workflow, index) => {
          return onRenderRow(workflow, index);
        })}
      </div>
    </Stack>
  );
};
export default NileWorkflowSearchSelector;
