import { gql } from '@apollo/client';

export const ProjectNileWorkflowsEndpoint = '/listBlobsInContainer';

const GetProjectNileWorkflows = gql`
  query ($keyword: String!) {
    projectNileWorkflows(keyword: $keyword) {
      id
      name
    }
  }
`;
export default GetProjectNileWorkflows;
