import {
  CommandBarButton,
  ICommandBarItemProps,
  IComponentAsProps,
  IContextualMenuItem,
  IContextualMenuProps,
  NeutralColors,
} from '@fluentui/react';
import React, { useContext, useState } from 'react';
import NotificationContext, { Toast } from '../../common/notifications/NotificationsContext';
import { restApiRequest } from '../../utils/api/ApiProvider';

async function downloadFile(
  fileEndpoint: string,
  fileName: string,
  method?: string,
  body?: string,
): Promise<void> {
  const endpoint = `dataexport/${fileEndpoint}`;
  await restApiRequest(endpoint, method, body).then(async (apiResponse) => {
    if (apiResponse.status === 200) {
      const data = await apiResponse.blob();
      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(data);
      anchor.download = fileName;
      anchor.click();
    } else {
      const errorMessage = await apiResponse.text();
      throw new Error(errorMessage);
    }
  });
}

export interface IDownloadButtonProps extends IComponentAsProps<ICommandBarItemProps> {
  fileEndpoint: string;
  fileName: string;
  postBody?: string;
  subItems?: IDownloadButtonProps[];
  hideMenuItemAriaRole?: boolean;
}

const DownloadButton: React.FunctionComponent<IDownloadButtonProps> = (
  props: IDownloadButtonProps,
) => {
  const {
    fileEndpoint,
    fileName,
    postBody,
    key,
    text,
    subItems,
    hideMenuItemAriaRole,
    buttonStyles,
    renderedInOverflow,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { addToast } = useContext(NotificationContext);

  const getTimestamp = (): string => {
    return new Date().toISOString().split('.')[0];
  };

  const kickoffDownloadFile = async (
    url: string,
    returnedFileName: string,
    filterParams: string,
  ): Promise<void> => {
    setLoading(true);
    const loadingToast: Toast = {
      id: Math.random(),
      content: 'File download started.  Download will continue in the background.',
      header: 'Document Download Started',
      iconName: 'DownloadDocument',
    };
    addToast(loadingToast);
    try {
      const fileNameWithTimestamp = `${returnedFileName}_${getTimestamp()}`;
      await downloadFile(url, fileNameWithTimestamp, 'POST', filterParams);
    } catch (error) {
      // show toast for error
      const errorToast: Toast = {
        id: Math.random(),
        content: error?.message || 'File download failed.',
        header: 'Document Download Error',
        iconName: 'ErrorBadge',
      };
      addToast(errorToast);
    } finally {
      setLoading(false);
    }
  };

  const handleNormalClick = async (): Promise<void> => {
    kickoffDownloadFile(fileEndpoint, fileName, postBody);
  };

  const menuProps: IContextualMenuProps = !subItems?.length
    ? undefined
    : {
        items: subItems.map((item: IDownloadButtonProps): IContextualMenuItem => {
          return {
            key: item.key,
            text: item.text,
            iconProps: item.iconProps,
            disabled: item.disabled,
            onClick: () => {
              kickoffDownloadFile(item.fileEndpoint, item.fileName, item.postBody);
            },
          };
        }),
      };

  // style to make the contextMenuItem render correctly in the overflow menu
  const styles = renderedInOverflow
    ? {
        label: {
          lineHeight: '36px',
        },
        icon: {
          color: NeutralColors.black,
          fontSize: '20px',
          width: '20px',
        },
      }
    : buttonStyles;

  return (
    <CommandBarButton
      key={key}
      role={hideMenuItemAriaRole ? '' : 'menuitem'}
      disabled={loading}
      text={text}
      ariaLabel={text}
      iconProps={{ iconName: 'ExcelLogo' }}
      onClick={handleNormalClick}
      menuProps={menuProps}
      styles={styles}
    />
  );
};

export default DownloadButton;
